<script setup></script>
<template>
  <div class="page-footer">
    <div class="links">
      <!-- <a
        class="link"
        href="https://github.com"
        target="_blank"
      >
        EasyMark 自动化营销引擎
      </a>
      <a
        class="link"
        href="https://github.com"
        target="_blank"
      >
        <GithubOutlined />
      </a> -->
      <!-- <a
        class="link"
        href="https://www.antdv.com/docs/vue/introduce-cn/"
        target="_blank"
      >
        Ant Design
      </a> -->
    </div>
    <div class="copyright">
      Copyright
      <CopyrightOutlined class="icon-copyright" /> 2023 EasyMark自动化营销引擎
    </div>
  </div>
</template>

<style scoped lang="less">
  .page-footer {
    text-align: center;
    @apply text-gray-400;

    .links {
      display: flex;
      justify-content: center;

      .link {
        @apply hover:text-gray-400 pl-4 pr-4;
      }
    }

    .copyright {
      margin-top: 0px;

      .icon-copyright {
        margin: 0;
      }
    }
  }
</style>
